import './install';
import React from 'react';
import { render } from 'react-dom';
import Auth from '@aws-amplify/auth';
import createMuiTheme from '@material-ui/core/styles/createMuiTheme';
import ThemeProvider from '@material-ui/styles/ThemeProvider';
import WebFont from 'webfontloader';
import awsExports from './aws-exports';
import { AuthProvider } from './contexts/AuthContext';
import App from './components/App';
import * as serviceWorker from './serviceWorker';
import { SnackbarProvider } from './contexts/SnackbarContext';

WebFont.load({
  google: {
    families: ['Roboto:300,400,500'],
  },
});

Auth.configure(awsExports);

const theme = createMuiTheme({
  palette: {
    primary: { light: '#D1C4E9', main: '#673AB7', dark: '#512DA8', contrastText: '#FFFFFF' },
    secondary: { light: '#FFF350', main: '#FFC107', dark: '#C79100', contrastText: '#000000' },
  },
  typography: {
    useNextVariants: true,
  },
});

render(
  <AuthProvider>
    <ThemeProvider theme={theme}>
      <SnackbarProvider>
        <App />
      </SnackbarProvider>
    </ThemeProvider>
  </AuthProvider>,
  document.getElementById('root')
);

serviceWorker.register();
