import React, { useState, createContext } from 'react';
import PropTypes from 'prop-types';
import Snackbar from '@material-ui/core/Snackbar';
import Button from '@material-ui/core/Button';

const SnackbarContext = createContext();
const queue = [];

function SnackbarProvider({ children }) {
  const [open, setOpen] = useState(false);
  const [messageInfo, setMessageInfo] = useState({});

  const processQueue = () => {
    if (queue.length > 0) {
      setOpen(true);
      setMessageInfo(queue.shift());
    }
  };

  const enqueueSnackbar = message => {
    queue.push({
      message,
      key: new Date().getTime(),
    });

    if (open) setOpen(false);
    else processQueue();
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') return;
    setOpen(false);
  };

  const handleExited = () => {
    processQueue();
  };

  return (
    <SnackbarContext.Provider value={{ enqueueSnackbar, open }}>
      <>
        {children}
        <Snackbar
          key={messageInfo.key}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          open={open}
          autoHideDuration={6000}
          onClose={handleClose}
          onExited={handleExited}
          message={<span id="message-id">{messageInfo.message}</span>}
          action={
            <Button color="secondary" size="small" onClick={handleClose}>
              Dismiss
            </Button>
          }
        />
      </>
    </SnackbarContext.Provider>
  );
}

SnackbarProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { SnackbarProvider };
export default SnackbarContext;
