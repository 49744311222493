// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  aws_project_region: 'ap-southeast-2',
  aws_cognito_identity_pool_id: 'ap-southeast-2:5e9f2c48-2292-4fc8-b0d9-c90e47ec1647',
  aws_cognito_region: 'ap-southeast-2',
  aws_user_pools_id: 'ap-southeast-2_egDvEPzRq',
  aws_user_pools_web_client_id: '6ove15gcf70bjhaahdvr1ou116',
  aws_appsync_graphqlEndpoint:
    'https://qidd73v7ifgihprdja5pscbtga.appsync-api.ap-southeast-2.amazonaws.com/graphql',
  aws_appsync_region: 'ap-southeast-2',
  aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
};

export default awsmobile;
